* {
  line-height: 30px;
}

a {
  color: #042e5acc;
  transition: 1s;
}

a:hover {
  color: #042e5a;
}

[aria-current="page"] {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: red;
}

img {
  width: 75%;
}
.kaliper img {
  width: 50% !important;
}

h3,
h4,
h5,
h6,
p,
span,
ul {
  margin-bottom: 10px;
}

h5{
  margin-top: 20px;
}

h1,
h2 {
  text-align: center;
  margin-block: 20px 30px;
}

/* Login */
.logindiv {
  width: 28%;
  margin: auto;
  text-align: center;
  padding-top: 100px;
  height: 100vh;
}

.logindiv input,
label {
  width: 90%;
}

.logindiv input {
  border: 1px solid #e5e6e7;
  width: 100%;
  margin: auto;
  padding: 3px 8px;
}

.logindiv input:focus {
  border: 1px solid rgb(139, 167, 139);
  outline: none;
}

.logindiv img {
  width: 60%;
  height: 90%;
  /* animation: pulse 5s infinite; */
}

.login-link {
  text-decoration: none;
  color: white !important;
  margin-bottom: 8px;
}

.logindiv form {
  background-color: transparent;
  padding: 20px;
  width: 90%;
  margin: 10px auto;
}

.logindiv p {
  color: white;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
}

.btn-send-password {
  background: #0d3b6c;
  opacity: 0.9;
  color: white;
  margin-bottom: 10px !important;
  transition: 1s;
}

.btn-send-password:hover {
  color: white;
  opacity: 1;
}

.link {
  top: 50%;
  display: table-cell;
  vertical-align: middle;
}

/* Navbar */

.nav-link {
  color: rgba(255, 255, 255, 0.849) !important;
}

.nav-link:hover {
  color: white !important;
}

.nav-link:active {
  color: white !important;
}

/* Filter Part */

.filter-part {
  display: flex;
  margin-block: 15px;
}

.container {
  width: 50%;
  margin: 0;
}

.input {
  padding: 5px 15px;
  width: 100%;
}
.select {
  width: 40%;
  font-size: 14px;
}

.user {
  list-style: none;
  margin: 10px 0;
  padding: 10px;
  background: #eee;
  display: flex;
  justify-content: space-between;
}

.user-id {
  color: red;
  margin-right: 20px;
}

.user-name {
  color: blue;
  width: 100%;
  cursor: pointer;
}

.button {
  padding: 5px 15px;
  height: 36px;
  background-color: #042e5ab6;
  color: white !important;
  transition: 1s;
}

.button:hover {
  background-color: #042e5a;
}

/* Safety Policy */

.our-documents {
  display: block;
  width: 60%;
  margin-inline: auto;
  text-align: center;
}

/* Footer */

.footer {
  padding-block: 20px 5px;
  color: white;
  background-color: #042e5a;
}

.button-footer {
  padding: 5px 15px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.863);
  color: #042e5a !important;
  transition: 1s;
}

.button-footer:hover {
  background-color: white;
}

/* For Scroll */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #0d3b6d;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background: #0d3b6d;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d3b6d;
}

.parts-body-upcoming {
  display: table;
}

/* Left Part */
.fault{
  display: flex;
  width: 95%;
  margin-inline: auto;
  padding-block: 16px;
}

.fault-left{
  width: 50%;
  margin-right: 2%;
  padding: 16px;
}

.fault-right{
  width: 50%;
  padding: 16px;
}

.number-parts {
  border: 0.05px solid #ec7424;
  border-radius: 5px 5px 0px 0px;
}

.parts-header {
  padding: 20px 1.5%;
  background-color: #ec7424;
  color: white;
  font-size: 18px;
}

.parts-body {
  width: 95%;
  margin: 15px auto;
}

.parts-body-parts {
  display: table;
}
.thead,
.tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  text-align: center;
}

.tbody {
  max-height: 400px;
  overflow-y: scroll;
  min-height: 300px;
  display: block;
}

.tbody td {
  text-align: center;
}

.right-part-button {
  color: white !important;
  text-align: center;
  background: #0d3b6d;
  border: 1px solid #0d3b6d;
  padding: 6px 10px;
  font-weight: 400;
  transition: 1s;
}

.right-part-button:hover {
  background: #042d5a;
  border: 1px solid #042d5a;
}

.right-part-button i {
  margin-right: 3.5px;
  font-size: 13px;
}

.right-part-clear-date {
  color: white !important;
  text-align: center;
  background: #6d0d0dab;
  border: 1px solid #6d0d0dab;
  padding: 6px 10px;
  font-weight: 400;
  transition: 1s;
}

.right-part-clear-date:hover {
  background: #6d0d0de3;
  border: 1px solid #6d0d0de3;
}

.modal-header-part {
  border-bottom: 3px solid #042d5a;
  color: #363f4d;
  margin-bottom: 5px;
  width: 95%;
  padding: 5px 0;
}

.modal-header-part-span {
  font-weight: 400;
  font-size: 22px;
  margin-top: 10px;
  width: 100%;
}

/*BackPage*/

/* burger menu */

#page-wrap {
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 25px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* BackPage Header Nav */
.header-nav{
  width: 100%;
  text-align: center;
  font-size:35px;
  padding:25px;
  background-color:#042e5aab;
}
.header-nav .header-link{
  color:white!important;
  text-decoration: none;
}

/* BackPage MyInformation */
.page-header{
  padding: 15px 75px;
  font-size: 28px;
  background-color: rgb(235, 235, 235);
  margin-block: 20px;
  color:#042d5a ;
}

.my-information,.users{
  display: flex;
  width: 90%;
  margin: auto;
}

/* BackPage Information */

.back-left, .back-right, .add-user-part{
  width: 48%;
  border: 0.5px outset rgb(4, 46, 90, 0.8);
  border-radius: 12px;
  background-color: rgb(251, 251, 251);;
}

.back-left{
  margin-right: 4%;
  height: 550px;
}

.add-user-part, .left-train-part{
  margin-right: 4%;
}

.left-train-part{
  width: 48%;
  background-color: rgb(251, 251, 251);;
}

.train-part{
  border: 0.5px outset rgb(4, 46, 90, 0.8);
  border-radius: 12px;
}

.part-header{
  padding:20px;
  background: rgb(4, 46, 90, 0.8);
  border-radius: 10px;
  color: white;
  font-size: 18px;
}

.part-body{
  width: 90%;
  margin: 25px auto;
}

.page-link{
  line-height: 30px;
}

.page-item{
  cursor: pointer;
}

/* BackPage Users */
.delete-button {
  color: white !important;
  text-align: center;
  background: #dc3546cc;
  border: 1px solid #dc3546cc;
  padding: 6px 10px;
  font-weight: 400;
}

.delete-button:hover {
  background: #dc3546;
  border: 1px solid #dc3546;
}

.update-button{
  color: white !important;
  text-align: center;
  background: #ec7424d2;
  border: 1px solid #ec7424d2;
  padding: 6px 10px;
  font-weight: 400;
}

.update-button:hover{
  background: #ec7424;
  border: 1px solid #ec7424;
}

/* BackPage User Istatistics */

.user-istatistics{
  width: 95%;
  margin: 30px auto;
}

.istatistics-filter-part{
  padding: 20px 30px;
}

/* Fault Back */
.fault-back{
  width: 98%;
  border: 0.5px outset rgb(4, 46, 90, 0.8);
  border-radius: 12px;
}

/* Back Footer */
.back-footer{
  color:white;
  background-color:#042e5a;
}

.back-footer marquee{
  font-size: 14px;
}

@media only screen and (max-width: 1000px) {
  .fault{
    display: block;
  }
  .fault-left,.fault-right{
    width: 100%;
    margin-right: 0%;
  }

  .my-information,.users{
    display: block;
  }
  
  .back-left, .back-right, .add-user-part, .left-train-part{
    width: 100%;
    margin-right: 0%;
  }

  .back-right{
    margin-top: 48px;
  }
}

@media only screen and (max-width: 760px) {
  * {
    font-size: 13px !important;
  }
  h1 {
    font-size: 19px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 17px !important;
  }
  h4 {
    font-size: 16px !important;
  }

  img {
    width: 100%;
    object-fit: scale-down;
  }

  .header-nav{
    font-size: 20px!important;
  }

  .page-header{
    font-size: 17px!important;
  }

  .logindiv {
    width: 60%;
  }
  .kaliper img {
    width: 100% !important;
    object-fit: scale-down;
  }

  .filter-part {
    display: block;
    width: 100%;
  }

  .container {
    width: 100%;
    padding: 0;
  }

  .container input {
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .user-name {
    font-size: 12px !important;
  }

  .select {
    width: 100%;
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  option {
    font-size: 8px !important;
  }

  .footer {
    padding-inline: 20px;
  }

  .footer .filter-part {
    display: block;
    width: 75%;
    margin-inline: auto;
  }

  .our-documents {
    width: 90%;
    margin-inline: auto;
  }
}
